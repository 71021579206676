exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-airdrop-js": () => import("./../../../src/pages/airdrop.js" /* webpackChunkName: "component---src-pages-airdrop-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-memeraids-js": () => import("./../../../src/pages/memeraids.js" /* webpackChunkName: "component---src-pages-memeraids-js" */),
  "component---src-pages-presale-testing-js": () => import("./../../../src/pages/presale.testing.js" /* webpackChunkName: "component---src-pages-presale-testing-js" */),
  "component---src-pages-referral-js": () => import("./../../../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-ssr-jsx": () => import("./../../../src/pages/ssr.jsx" /* webpackChunkName: "component---src-pages-ssr-jsx" */),
  "component---src-pages-staking-js": () => import("./../../../src/pages/staking.js" /* webpackChunkName: "component---src-pages-staking-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

